import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';

import ReceptionImage from '../components/images/ReceptionImage';

import styles from './franchising.module.scss';

const FranchisingPage = props => (
  <Layout location={props.location} navAlwaysFixed>
    <SEO title="加入儲方" />
    <section className={styles.hero}>
      <ReceptionImage />
    </section>
    <section className={styles.content}>
      <h1>加入儲方</h1>
      <p>
        迷你倉在香港需求日益增長，立米樓的出現更加快推動需求的速度。『Roomy儲方』配備領先行業的線上管理系統，重視新媒體上的發展，具備與網上消費的大催勢接軌能力。總公司會提供合法的場地設計，保安系統，運輸物流，客戶服務及培訓的支援，加盟者無須全職投身管理，可視為投資項目。
      </p>

      <a className={styles.button} href="mailto:info@roomyr.com">
        <Button>電郵聯絡我們</Button>
      </a>
    </section>
  </Layout>
);

export default FranchisingPage;
